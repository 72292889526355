import { LogoJsonLdProps } from 'next-seo';

import { LocaleType } from '@types';

import { routesConstants } from '@constants';
import { getSeo } from '@utils/_seo';

export const getPageSeo = (locale: LocaleType) => {
  return getSeo({
    route: routesConstants.HOME,
    title: 'FIDE Online Arena - Official FIDE Gaming Platform',
    description:
      'Start your chess arena journey with FIDE’s official platform for online chess. Join a global community – play how you like, earn recognized ratings, participate in tournaments, solve puzzles, and improve through masterclasses!',
    locale,
    images: [
      {
        url: 'https://gaming-images.worldchess.com/media/meta/og-chessarena.png',
        width: 1200,
        height: 630,
        type: 'image/png',
      },
    ],
  });
};

export const getPageLogoJsonLd = (): LogoJsonLdProps => {
  return {
    logo: 'https://gaming-images.worldchess.com/media/icon.png',
    url: process.env.NEXT_PUBLIC_GAME_URL || '',
  };
};
