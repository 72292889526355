import { createAuthorizationHeaders } from '@utils/_requests';
import { combineURLParamsByMap } from '@utils/_url_helpers';
import { httpInstance } from '.';
import { BaseService } from './_base.service';
import { eAccessLevel } from '@types';

export interface IApiMasterclassAuthorAvatar {
  small?: string;
  medium?: string;
  full?: string;
}

export interface IApiMasterclassAuthor {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  fide_id?: string;
  fide_verified_status?: number;
  access_level?: eAccessLevel;
  avatar?: IApiMasterclassAuthorAvatar;
}

export interface IApiMasterclassesItem {
  url: string;
  title: string;
  created_at?: string;
  published?: boolean;
  access_level: number;
  translation_datetime: string;
  author: IApiMasterclassAuthor;
  video_preview: string;
  video_preview_background: string | null;
  video_preview_emoticon: string | null;
  video_preview_foreground: string | null;
}

export interface IApiMasterclass extends IApiMasterclassesItem {
  description: string;
  video_url?: string | null;
  previous?: IApiMasterclassesItem;
  next?: IApiMasterclassesItem;
}

export interface IApiMasterclassesGetListRequestParams {
  category?: number;
  autor?: number;
  limit?: number;
  offset?: number;
}

export interface IApiMasterclassesGetListResponseData {
  count: number;
  results: IApiMasterclass[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApiMasterclassesGetItemByIdResponseData
  extends IApiMasterclass {}

const SERVICE_URLS = {
  GET_MASTERCLASSES: '/learning/masterclasses/',
  GET_MASTERCLASS_BY_ID: '/learning/masterclasses/:id',
} as const;

export class MasterclassesService extends BaseService {
  getList(params: IApiMasterclassesGetListRequestParams) {
    const { category, autor, limit = 10, offset = 0 } = params;

    const url = combineURLParamsByMap(SERVICE_URLS.GET_MASTERCLASSES, {
      category,
      autor,
      limit,
      offset,
    });

    return this.ajax.get<IApiMasterclassesGetListResponseData>(url);
  }

  getItemById(id: string, jwt?: string) {
    const url = SERVICE_URLS.GET_MASTERCLASS_BY_ID.replace(':id', id);

    return this.ajax.get<IApiMasterclassesGetItemByIdResponseData>(
      url,
      createAuthorizationHeaders(jwt)
    );
  }
}

export const masterclassesService = new MasterclassesService({
  instance: httpInstance,
});
