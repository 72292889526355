import HomeSpecialProjects from '@chessarena/components/lib/home/HomeSpecialProjects';
import { FC, memo } from 'react';
import { useSpecialProjectsFragmentData } from './useSpecialProjectsFragmentData.hook';

export const SpecialProjectsFragment: FC = memo(
  function SpecialProjectsFragment() {
    const { visibility, items, renderLink } = useSpecialProjectsFragmentData();

    if (!visibility) return null;

    return <HomeSpecialProjects items={items} renderLink={renderLink} />;
  }
);
