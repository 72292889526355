import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { UserStatus } from '@utils/hooks/_useUserSatatus';
import {
  eFideIdStatus,
  GameRatingMode,
  ITournamentExpandedData,
  JoinButtonState,
  TournamentParticipantStatus,
} from '@types';
import { RatingType } from '@chessarena/components/lib/common/types';
import { formatNumber } from '@utils/_helpers/_common';

dayjs.extend(utc);

const utcOffset = {
  UTC: 0,
  CET: 60,
  CEST: 120,
};

export const getDatetimeFormatted = (
  date?: string,
  timezone: 'UTC' | 'CET' | 'CEST' = 'CET'
) => {
  if (!date) {
    return '';
  }

  return dayjs(date)
    .utc()
    .utcOffset(utcOffset[timezone])
    .format(`D MMM YYYY, HH:mm [${timezone}]`);
};

const currencyCharacterMap = {
  usd: '$',
  eur: '€',
} as const;

export const getPaidJoinText = (
  base: string,
  tournamentData: ITournamentExpandedData | null
) => {
  if (!tournamentData || !tournamentData.entry_price) return '';

  const amount = formatNumber(tournamentData.entry_price.amount ?? 0 / 100, 2);

  const currency =
    (tournamentData.entry_price.currency as 'eur' | 'usd') ?? 'eur';

  return `${base} ${currencyCharacterMap[currency]}${amount}`;
};

export const getJoinButtonState = (
  tournamentData: {
    player_status?: TournamentParticipantStatus | null;
    rating_type?: GameRatingMode | null;
    signup_opened?: boolean;
  } | null,
  isTournamentYou?: boolean | null,
  isPaidTournament?: boolean | null,
  userStatus?: UserStatus,
  fideIdStatus?: eFideIdStatus
) => {
  if (!tournamentData || !tournamentData.signup_opened) return null;

  if (
    [
      TournamentParticipantStatus.REGISTERED,
      TournamentParticipantStatus.CHECKED_IN,
    ].includes(tournamentData.player_status as TournamentParticipantStatus) ||
    isTournamentYou
  )
    return JoinButtonState.JOINED;

  if (
    tournamentData.rating_type === GameRatingMode.FIDERATED &&
    // ЛИБО не подписаны, либо фиде айди НЕ подключен (НЕ одобрен)
    (userStatus !== UserStatus.SUBSCRIBED ||
      fideIdStatus !== eFideIdStatus.CONNECTED)
  ) {
    // Мы подписаны И фиде не подключен (мы не заполнили фиде форму)
    if (
      userStatus === UserStatus.SUBSCRIBED &&
      fideIdStatus === eFideIdStatus.NOT_CONNECTED
    ) {
      return JoinButtonState.FIDE_ID_NOT_CONNECTED;
    }

    // Не одобрили фиде заявку или же на проверке
    if (
      fideIdStatus === eFideIdStatus.REJECTED ||
      fideIdStatus === eFideIdStatus.ON_CHECK
    ) {
      return JoinButtonState.JOIN_DISABLED_WITH_CAPTION;
    }

    return JoinButtonState.NEEDS_PRO_ACCOUNT;
  }

  if (isPaidTournament) return JoinButtonState.PAID_TOURNAMENT;

  return JoinButtonState.COMMON;
};

export const ratingTypesMap: Record<GameRatingMode, RatingType> = {
  [GameRatingMode.UNRATED]: 'non-rated',
  [GameRatingMode.RATED]: 'worldchess',
  [GameRatingMode.FIDERATED]: 'fide',
  [GameRatingMode.OTB]: 'otb',
};
