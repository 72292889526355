import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';

import formatLabel from '@chessarena/components/lib/utils/formatLabel';
import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';

import { routesConstants } from '@constants';
import { useApplicationContext } from '@application';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

import { HomePlayersItem } from '@chessarena/components/lib/home/HomePlayers/types';
import { useHeaderSectionContext } from '../context';
import { adaptGamesInQueue } from '../helpers/adaptGamesInQueue';

export const usePlayersItems = () => {
  const { localization: l } = useApplicationContext();
  const {
    state: {
      stats: { data: stats },
    },
  } = useHeaderSectionContext();
  const {
    state: { gamesInQueue },
  } = useLobbyContext();

  const link = useMemo(
    () => ({
      href: routesConstants.LOBBY,
      text: l.main_page?.header.playing_right_now,
    }),
    [l.main_page?.header.playing_right_now]
  );

  // Значение, которое обновляется лишь один раз при загрузке
  const [adaptedGamesInQueueInitial, setAdaptedGamesInQueueInitial] = useState<
    HomePlayersItem[]
  >([]);
  const [hasGamesInQueueBeenInited, setHasGamesInQueueBeenInited] =
    useState(false);

  // Значение, которое обновляется от gamesInQueue, динамическое
  // const adaptedGamesInQueue = useMemo(
  //   () => adaptGamesInQueue(gamesInQueue),
  //   [gamesInQueue]
  // );

  useEffect(() => {
    if (
      gamesInQueue &&
      gamesInQueue?.length > 0 &&
      !hasGamesInQueueBeenInited
    ) {
      setHasGamesInQueueBeenInited(true);
      const adaptedGames = adaptGamesInQueue(gamesInQueue);
      setAdaptedGamesInQueueInitial(adaptedGames);
    }
  }, [gamesInQueue, hasGamesInQueueBeenInited]);

  const renderLink = ({ link, children }: RenderLinkParams) => {
    return (
      <Link
        href={link}
        onClick={() => gtmPush({ event: GTM_EVENTS.USERS_ICONS_CLICK })}
        prefetch={false}
      >
        {children}
      </Link>
    );
  };

  const playersData = useMemo(
    () => ({
      link,
      label: formatLabel(l.main_page?.header.players, {
        count: stats?.activePlayers ?? 0,
      }),
      items: adaptedGamesInQueueInitial,
      renderLink,
    }),
    [
      adaptedGamesInQueueInitial,
      l.main_page?.header.players,
      link,
      stats?.activePlayers,
    ]
  );

  return playersData;
};
