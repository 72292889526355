import dayjs from 'dayjs';

import { HomeTournamentsListItem } from '@chessarena/components/lib/home/HomeTournamentsList/types';

import { ILocalization, ITournamentData } from '@types';
import { getLocalizedTimeControl } from 'shared/helpers/_game';
import { getTournamentType } from 'shared/helpers/_tournament';

import { routesConstants } from '@constants';
import { ratingTypesMap } from '@utils/_tournament';

export function getScheduledTournament(
  tournament: ITournamentData,
  my: ITournamentData[],
  l: ILocalization,
  prev?: ITournamentData
): HomeTournamentsListItem {
  const slug = tournament.slug || tournament.id.toString();
  const section = dayjs(tournament.start).format('MMMM DD, dddd');
  const prevSection = prev
    ? dayjs(prev.start).format('MMMM DD, dddd')
    : undefined;
  return {
    id: tournament.id.toString(),
    link: `${routesConstants.TOURNAMENTS}/${slug}`,
    title: tournament.title || '',
    section: section !== prevSection ? section : null,
    time: dayjs(tournament.start).format('HH:mm'),
    ratingType: ratingTypesMap[tournament.rating_type],
    timeControl: getLocalizedTimeControl(
      tournament.time_control,
      l.board_types,
      l.time?.minutes
    ),
    tournamentType: l.tournament_types?.[getTournamentType(tournament.kind)],
    featured: tournament.featured,
    isMy: !!my.find((item) => item.id === tournament.id),
  };
}

export function getScheduledTournaments(
  scheduled: ITournamentData[],
  my: ITournamentData[],
  l: ILocalization
): HomeTournamentsListItem[] {
  const result: HomeTournamentsListItem[] = [];

  if (scheduled.length) {
    scheduled.forEach((item, i) => {
      result.push(getScheduledTournament(item, my, l, scheduled[i - 1]));
    });
  }

  return result;
}
