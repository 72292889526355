import { FC, PropsWithChildren, useMemo } from 'react';

import { useHomeContext } from '../../../context';

import { SpecialProjectsSectionContext } from './_context';
import { ISpecialProjectsSectionContext } from './_types';

export const SpecialProjectsSectionContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useHomeContext();

  const value = useMemo<ISpecialProjectsSectionContext>(
    () => ({
      data: data?.sections.special_projects,
    }),
    [data?.sections.special_projects]
  );

  return (
    <SpecialProjectsSectionContext.Provider value={value}>
      {children}
    </SpecialProjectsSectionContext.Provider>
  );
};
