import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { ITourGameData, TournamentBoardStatus } from '@types';

import { useActions } from './_actions';
import { TopGameContext } from './_context';
import { createInitState, TopGameReducer } from './_reducer';
import { ITopGameContextProps } from './_types';

import { useTopRatedGameWs } from './hooks/_useTopRatedGameWs.hook';
import { getTopRatedGame } from '../../fetchData/_getTopRatedGame';

let timerId: NodeJS.Timeout;

export const TopGameContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(TopGameReducer, createInitState());

  const { setLoading, setTopRatedGame } = useActions(dispatch);

  const getTopGame = useCallback(async () => {
    let data: ITourGameData | null = null;
    try {
      setLoading(true);
      data = await getTopRatedGame();
    } catch (e) {
      console.log('get top rated game error', e);
    } finally {
      setLoading(false);
      setTopRatedGame(data);

      if (!data || data.status === TournamentBoardStatus.COMPLETED) {
        timerId = setTimeout(getTopGame, 10000);
      }
    }
  }, []);

  const topRatedGameWs = useTopRatedGameWs(getTopGame, state.data.topRatedGame);

  useEffect(() => {
    getTopGame();
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const value = useMemo<ITopGameContextProps>(() => {
    return {
      state,
      computed: { topRatedGameWs },
      actions: { setTopRatedGame },
    };
  }, [state, topRatedGameWs, setTopRatedGame]);

  return (
    <TopGameContext.Provider value={value}>{children}</TopGameContext.Provider>
  );
};
