import { Dispatch, useCallback } from 'react';

import { GameRatingMode } from '@types';

import {
  eQuickGameSectionReducerActionTypes,
  IQuickGameSectionReducerAction,
} from './_types';
import { IQuickGameTab } from '@pages/home/sections/QuickGameSection/_types';

export const useActions = (
  dispatch: Dispatch<IQuickGameSectionReducerAction>
) => {
  return {
    setMounted: useCallback(
      (value: boolean): void => {
        return dispatch({
          type: eQuickGameSectionReducerActionTypes.SET_MOUNTED,
          payload: value,
        });
      },
      [dispatch]
    ),

    setTabs: useCallback(
      (data: IQuickGameTab[]): void => {
        return dispatch({
          type: eQuickGameSectionReducerActionTypes.SET_TABS,
          payload: data,
        });
      },
      [dispatch]
    ),

    setActiveTabId: useCallback(
      (value: GameRatingMode): void => {
        return dispatch({
          type: eQuickGameSectionReducerActionTypes.SET_ACTIVE_TAB_ID,
          payload: value,
        });
      },
      [dispatch]
    ),

    setGameIsStarting: useCallback(
      (value: boolean): void => {
        return dispatch({
          type: eQuickGameSectionReducerActionTypes.SET_GAME_IS_STARTING,
          payload: value,
        });
      },
      [dispatch]
    ),

    setInviteSettingsOpened: useCallback(
      (value: boolean): void => {
        return dispatch({
          type: eQuickGameSectionReducerActionTypes.SET_INVITE_SETTINGS_OPENED,
          payload: value,
        });
      },
      [dispatch]
    ),

    setLoading: useCallback(
      (value: boolean): void => {
        return dispatch({
          type: eQuickGameSectionReducerActionTypes.SET_LOADING,
          payload: value,
        });
      },
      [dispatch]
    ),

    setError: useCallback(
      (error: string): void => {
        return dispatch({
          type: eQuickGameSectionReducerActionTypes.SET_ERROR,
          payload: error,
        });
      },
      [dispatch]
    ),
  };
};
