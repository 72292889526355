"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const throttle = (callback, delay) => {
    let timeout = false;
    return function (...args) {
        if (timeout)
            return;
        timeout = true;
        callback(...args);
        setTimeout(() => {
            timeout = false;
        }, delay);
    };
};
exports.default = throttle;
