import { createContext, useContext } from 'react';

import { ISpecialProjectsSectionContext } from './_types';

export const SpecialProjectsSectionContext = createContext(
  {} as ISpecialProjectsSectionContext
);

export const useSpecialProjectsSectionContext = () =>
  useContext(SpecialProjectsSectionContext);
