import { ratingsService } from '@services/_ratings.service';

import { TTopPlayersByRatingResponse, AccountVerification } from '@types';
import { IPuzzlesSectionRatingItem } from '../_types';

function adaptResponseData(
  data: TTopPlayersByRatingResponse
): IPuzzlesSectionRatingItem[] {
  return data.puzzle.map((item) => ({
    standing: item.rank,
    progress: item.deltas.rank || 0,
    rating: item.ratings.puzzle || 0,
    player: {
      firstname: item.full_name?.split(', ')?.[1] || null,
      lastname: item.full_name?.split(', ')?.[0] || null,
      title: item.title || null,
      avatar: {
        src: item.avatar || null,
        premium: item.fide_verified_status === AccountVerification.VERIFIED,
        online: item.is_online || false,
      },
    },
  }));
}

export async function getRating(): Promise<IPuzzlesSectionRatingItem[]> {
  const { data } = await ratingsService.getTopPlayersByRatingType(
    'worldchess',
    20
  );

  if (data) {
    const adapted = adaptResponseData(data);
    return adapted;
  }

  throw new Error('get puzzles rating executed without ok status');
}
