import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';
import { useSpecialProjectsSectionContext } from '../context';
import Link from 'next/link';

export const useSpecialProjectsFragmentData = () => {
  const { data } = useSpecialProjectsSectionContext();

  const renderLink = (params: RenderLinkParams) => {
    const { link, children } = params;

    return <Link href={link}>{children}</Link>;
  };

  return {
    visibility: !!data,
    items: data || [],
    renderLink,
  };
};
