import { IRequestData, ITourGameData } from '@types';
import { ITopRatedGameWs } from '../../_types';

export interface ITopGameContextStateData {
  topRatedGame?: ITourGameData | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITopGameContextState
  extends IRequestData<ITopGameContextStateData> {}

export interface ITopGameContextComputed {
  topRatedGameWs?: ITopRatedGameWs | null;
}

export interface ITopGameContextActions {
  setTopRatedGame: (game: ITourGameData) => void;
}

export interface ITopGameContextProps {
  state: ITopGameContextState;
  computed: ITopGameContextComputed;
  actions: ITopGameContextActions;
}

export enum eTopGameReducerActionTypes {
  SET_LOADING,
  SET_ERROR,
  SET_TOP_RATED_GAME,
}

interface ITopGameReducerActionSetLoading {
  type: eTopGameReducerActionTypes.SET_LOADING;
  payload: boolean;
}

interface ITopGameReducerActionSetError {
  type: eTopGameReducerActionTypes.SET_ERROR;
  payload: string;
}

interface ITopGameReducerActionSetTopRatedGame {
  type: eTopGameReducerActionTypes.SET_TOP_RATED_GAME;
  payload: ITourGameData | null;
}

export type ITopGameReducerAction =
  | ITopGameReducerActionSetLoading
  | ITopGameReducerActionSetError
  | ITopGameReducerActionSetTopRatedGame;
