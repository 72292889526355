import { FC, memo } from 'react';
import { SpecialProjectsSectionContextProvider } from './context';
import { SpecialProjectsFragment } from './fragments/SpecialProjectsFragment';

export const SpecialProjectsSection: FC = memo(
  function SpecialProjectsSection() {
    return (
      <SpecialProjectsSectionContextProvider>
        <SpecialProjectsFragment />
      </SpecialProjectsSectionContextProvider>
    );
  }
);
