import { useMemo, useRef } from 'react';
import Link from 'next/link';

import { useTopGameBoardSize } from '@chessarena/components/lib/home/HomeQuickGameTopGame';
import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';

import { routesConstants } from '@constants';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

import { useHomeContext } from '../../context';

import { TopGameBoard } from './fragments/TopGameBoard';
import { useTopGameContext } from './contexts/top-game';

export const useQuickGameTopGameData = () => {
  const {
    computed: { topRatedGameWs },
  } = useTopGameContext();
  const { data } = useHomeContext();

  const nodeRef = useRef(null);
  const boardSize = useTopGameBoardSize(nodeRef);

  const renderLink = ({ link, children }: RenderLinkParams) => {
    return (
      <Link
        href={link}
        prefetch={false}
        onClick={() => gtmPush({ event: GTM_EVENTS.TOP_GAME_LIVE_CLICK })}
      >
        {children}
      </Link>
    );
  };

  return useMemo(
    () => ({
      link: topRatedGameWs?.data?.board_id
        ? `${routesConstants.GAME}/${topRatedGameWs?.data?.board_id}`
        : undefined,
      title: data?.sections.quick_game.top_game.title || '',
      live: data?.sections.quick_game.top_game.live || '',
      wait: data?.sections.quick_game.top_game.wait || '',
      loading: !topRatedGameWs?.moves?.length && !topRatedGameWs?.ws,
      whitePlayer: topRatedGameWs?.data
        ? topRatedGameWs.data?.white_player?.full_name || 'Anonymous'
        : '',
      blackPlayer: topRatedGameWs?.data
        ? topRatedGameWs.data?.black_player?.full_name || 'Anonymous'
        : '',
      nodeRef,
      renderLink,
      children: <TopGameBoard boardSize={boardSize} />,
    }),
    [data, boardSize, topRatedGameWs]
  );
};
