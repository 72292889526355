import { IQuickGameTab } from '@pages/home/sections/QuickGameSection/_types';

import { BoardType, GameRatingMode, ILocalization, ITimeControl } from '@types';
import { IHomePageData } from '@pages/home/_types';
import { getTimeControlTime } from '@utils/_getTimeControlTime';

const getLegend = (timeControl: ITimeControl, data?: IHomePageData) => {
  switch (timeControl.board_type) {
    case BoardType.RAPID:
    case BoardType.BLITZ:
    case BoardType.BULLET:
      return undefined;
    default:
      return data?.sections.quick_game.statuses.new;
  }
};

export const getHomeTimeControls = (
  tabs: IQuickGameTab[],
  l: ILocalization,
  activeTabId?: GameRatingMode | null,
  data?: IHomePageData
) => {
  const items = tabs.find((item) => item.id === activeTabId)?.items;
  if (!items) return [];

  return items.map((item) => {
    const { time } = getTimeControlTime(item.timeControl, l);
    return {
      id: item.id,
      active: item.active,
      time: time,
      title:
        l.common.time_controls.full_names[item.timeControl.board_type_name],
      legend: getLegend(item.timeControl, data),
    };
  });
};
