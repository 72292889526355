import { useCallback, useState } from 'react';

import { HomeTournamentCardActionClickParams } from '@chessarena/components/lib/home/HomeTournamentCard/types';

import { GameRatingMode } from '@types';

import { useUserDataStore } from '@store/storeshed';
import { tournamentService } from '@services/_tournament.service';
import { ePopupPaths, routesConstants } from '@constants';
import { IRequestError } from '@types';
import { useAlertPopup } from '@utils/hooks/_useAlertPopup.hook';
import { openPopup } from '@utils/_router';
import { ePaywallPopupType } from '@components/molecules/popups/PaywallPopup/_types';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';
import { useRouter } from 'next/router';

import { useFeaturedTournamentsLeave } from './_useFeaturedTournamentsLeave.hook';
import { useHomeTournamentsContext } from './context';

export const useFeaturedTournamentsAction = () => {
  const router = useRouter();

  const [disabledItems, setDisabledItems] = useState<number[]>([]);

  const userData = useUserDataStore('data');

  const { getData, data: sectionData } = useHomeTournamentsContext();

  const leaveConfirmation = useFeaturedTournamentsLeave();

  const {
    isAlertPopupShown,
    alertPopupRef,
    onClose,

    alertText,
    showAlertPopup,
  } = useAlertPopup();

  const onJoinTournament = useCallback(
    async (tournamentId: number) => {
      try {
        setDisabledItems([...disabledItems, tournamentId]);
        const { ok } = await tournamentService.joinTournament(tournamentId);
        if (ok) {
          gtmPush({ event: GTM_EVENTS.JOINED_TO_TOURNAMENT });
          getData(true);
        }
      } catch (e) {
        console.log('join error', e);
        const data = (
          e as IRequestError<{
            signup_error?: string;
            signup_error_code?: string;
          }>
        )?.data;
        if (data?.signup_error) showAlertPopup(data?.signup_error);
      } finally {
        setDisabledItems(disabledItems.filter((item) => item !== tournamentId));
      }
    },
    [disabledItems, getData, showAlertPopup]
  );

  const onJoinClick = useCallback(
    (tournamentId: number) => {
      gtmPush({ event: GTM_EVENTS.JOIN_TOURNAMENT_CLICK });

      const tournament = sectionData?.featured.find(
        (item) => item.id === tournamentId
      );

      if (!userData) {
        const isFideRated =
          tournament?.rating_type === GameRatingMode.FIDERATED;

        openPopup(
          `?popup=${ePopupPaths.REGISTRATION}&pro=${
            isFideRated ? 'true' : 'false'
          }`
        );
        return;
      }

      onJoinTournament(tournamentId);
    },
    [onJoinTournament, sectionData?.featured, userData]
  );

  const onRegistartionClick = useCallback((): void => {
    openPopup(`?popup=${ePopupPaths.REGISTRATION}&pro=true`);
    return;
  }, []);

  const onUpgradeClick = useCallback((): void => {
    openPopup(
      `?popup=${ePopupPaths.PAYWALL}&type=${ePaywallPopupType.TOURNAMENTS}`
    );
  }, []);

  const onConnectClick = useCallback((): void => {
    openPopup(`?popup=${ePopupPaths.FIDE}`);
  }, []);

  const onActionClick = useCallback(
    (params: HomeTournamentCardActionClickParams) => {
      const tournamentId = Number(params.tournamentId);
      const id = params.id;

      switch (id) {
        case 'leave':
          return leaveConfirmation.onLeaveActionClick(params);
        case 'join':
          return onJoinClick(tournamentId);
        case 'play':
        case 'watch':
          return router.push(`${routesConstants.TOURNAMENTS}/${tournamentId}`);
        case 'registration':
          return onRegistartionClick();
        case 'upgrade':
          return onUpgradeClick();
        case 'connect':
          return onConnectClick();
        default:
          return null;
      }
    },
    [
      leaveConfirmation,
      onConnectClick,
      onJoinClick,
      onRegistartionClick,
      onUpgradeClick,
      router,
    ]
  );

  return {
    disabledItems,
    onActionClick,
    leaveConfirmation,
    alert: {
      isAlertPopupShown,
      alertPopupRef,
      alertText,
      onClose,
    },
  };
};
