import {
  HomeTournamentCardAction,
  HomeTournamentCardMessage,
} from '@chessarena/components/lib/home/HomeTournamentCard/types';
import { routesConstants } from '@constants';
import { ITournamentExpandedWithPlayersData } from '@pages/home/_types';
import { eFideIdStatus } from '@types';
import { UserStatus } from '@utils/hooks/_useUserSatatus';
import Link from '@chessarena/components/lib/general/Link';
import {
  ILocalization,
  ITournamentData,
  TournamentJoinLimit,
  TournamentStatus,
} from '@types';

const messages: Record<string, HomeTournamentCardMessage> = {
  participantsLimit: {
    type: 'red',
    title: 'Unable to add more players.',
    description:
      'Try to pick one of our other tournaments open for all players',
    readMore: {
      text: 'Learn more about tournaments ›',
      href: routesConstants.TOURNAMENTS,
    },
  },
  specialLimit: {
    type: 'red',
    title: 'This tournament is for some special players only',
    description:
      'Try to pick one of our other tournaments open for all players',
    readMore: {
      text: 'Learn more about tournaments ›',
      href: routesConstants.TOURNAMENTS,
    },
  },
  femaleOnlyLimit: {
    type: 'pink',
    title: 'This tournament is for female players only',
    description:
      'Try to pick one of our other tournaments open for all players',
    readMore: {
      text: 'Learn more about tournaments ›',
      href: routesConstants.TOURNAMENTS,
    },
  },
  subscribedOnlyLimit: {
    type: 'yellow',
    title: 'Upgrade to pro',
    description:
      'Start competing for FIDE Online Arena rating recognized by FIDE, gain access to exclusive masterclasses and live sessions with GMs, and many more!',
    readMore: {
      text: 'Read more about Pro perks ›',
      href: routesConstants.PRICING,
    },
    action: { id: 'upgrade', type: 'yellow', title: 'Upgrade to Pro' },
    cancel: { id: 'cancel', type: 'secondary', title: 'Cancel' },
  },
  fideOnCheckLimit: {
    type: 'capri',
    title: 'Your FIDE ID waiting for approval',
    description:
      'Try to pick one of our other tournaments open for all players',
    readMore: {
      text: 'Read more about benefits having an official FIDE ID ›',
      href: routesConstants.PRICING,
    },
  },
  fidePlayersOnlyLimit: {
    type: 'capri',
    title: 'get your FIDE ID',
    description:
      'Start competing for FIDE Online Arena rating recognized by FIDE, gain access to exclusive masterclasses and live sessions with GMs, and many more!',
    readMore: {
      text: 'Read more about benefits having an official FIDE ID ›',
      href: routesConstants.PRICING,
    },
    action: { id: 'connect', type: 'capri', title: 'Connect your FIDE ID' },
    cancel: { id: 'cancel', type: 'secondary', title: 'Cancel' },
  },
  fideIdFormRejected: {
    type: 'red',
    title: 'your FIDE id form rejected',
    description: (
      <>
        Your FIDE ID got rejected which might be due to a number of reasons.
        Please contact us at{' '}
        <Link type="secondary" href={'mailto:support@chessarena.com'}>
          support@chessarena.com
        </Link>
      </>
    ),
    cancel: { id: 'cancel', type: 'secondary', title: 'Close' },
  },
  fideAccountUnderApproval: {
    type: 'capri',
    title: 'your FIDE ID is under approval',
    description:
      'You have just become a PRO member, so your info is not synced with fiDE yet. No worries, it usually takes 24 hours.',
    readMore: {
      text: 'Read more about FIDE ID troubleshooting ›',
      href: `https://support.chessarena.com/en/articles/6829505-why-i-don-t-see-myself-on-fide-or-my-info-is-incorrect`,
    },
    cancel: { id: 'cancel', type: 'secondary', title: 'Close' },
  },
};

export function getActionAndMessage(
  tournament: ITournamentExpandedWithPlayersData,
  my: ITournamentData[],
  userStatus: UserStatus,
  fideIdStatus: eFideIdStatus,
  disabledItems: number[],
  l: ILocalization
): {
  action: HomeTournamentCardAction | undefined;
  message: HomeTournamentCardMessage | undefined;
} {
  const isMy = !!my.find((item) => item.id === tournament.id);

  let action: HomeTournamentCardAction | undefined = undefined;
  let message: HomeTournamentCardMessage | undefined = undefined;

  const disabled = disabledItems.includes(tournament.id);

  if (isMy) {
    if (tournament.status === TournamentStatus.GOES) {
      const title = l.tournaments_page?.featured_tournaments?.actions?.play;
      action = { id: 'play', title };
      return { action, message };
    }

    const title = l.tournaments_page?.featured_tournaments?.actions?.leave;
    action = { id: 'leave', title, disabled };
    return { action, message };
  }

  if (tournament.unavailability_reason) {
    if (
      tournament.unavailability_reason ===
        TournamentJoinLimit.GENDER_NOT_ALLOWED &&
      tournament.allowed_gender === 'female'
    ) {
      message = messages.femaleOnlyLimit;
      return { action, message };
    }

    if (
      tournament.unavailability_reason === TournamentJoinLimit.FIDE_PLAYERS_ONLY
    ) {
      if (userStatus !== UserStatus.SUBSCRIBED) {
        message = messages.subscribedOnlyLimit;
        return { action, message };
      }

      if (fideIdStatus === eFideIdStatus.REJECTED) {
        message = messages.fideIdFormRejected;
        return { action, message };
      }

      if (fideIdStatus === eFideIdStatus.ON_CHECK) {
        message = messages.fideAccountUnderApproval;
        return { action, message };
      }

      if (fideIdStatus !== eFideIdStatus.NOT_CONNECTED) {
        message = messages.fideOnCheckLimit;
        return { action, message };
      }

      message = messages.fidePlayersOnlyLimit;
      return { action, message };
    }

    message = messages.specialLimit;

    return { action, message };
  }

  if (
    tournament.max_participants &&
    tournament.max_participants === tournament.signed_up_amount
  ) {
    message = messages.participantsLimit;

    return { action, message };
  }

  const title = l.tournaments_page?.featured_tournaments?.actions?.join;

  if (userStatus === UserStatus.UNAUTHORIZED) {
    action = { id: 'registration', title, disabled };
    return { action, message };
  }

  if (tournament.status === TournamentStatus.GOES) {
    const title = l.tournaments_page?.featured_tournaments?.actions?.watch;
    action = { id: 'watch', title };
    return { action, message };
  }

  action = { id: 'join', title, disabled };
  return { action, message };
}
