import { IRequestData } from '@types';
import { GameRatingMode } from '@types';
import { IQuickGameTab } from '../../_types';

export interface IQuickGameSectionContextStateData {
  mounted: boolean;
  activeTabId?: GameRatingMode | null;
  tabs: IQuickGameTab[];
  gameIsStarting: boolean;
  inviteSettingsOpened: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQuickGameSectionContextState
  extends IRequestData<IQuickGameSectionContextStateData> {}

export interface IQuickGameSectionContextComputed {
  tabs: IQuickGameTab[];
}

export interface IQuickGameSectionContextActions {
  setActiveTabId: (value: GameRatingMode) => void;
  setTabs: (data: IQuickGameTab[]) => void;
  setInviteSettingsOpened: (value: boolean) => void;
}

export interface IQuickGameSectionContextProps {
  state: IQuickGameSectionContextState;
  computed: IQuickGameSectionContextComputed;
  actions: IQuickGameSectionContextActions;
}

export enum eQuickGameSectionReducerActionTypes {
  SET_MOUNTED,
  SET_TABS,
  SET_LOADING,
  SET_ERROR,
  SET_ACTIVE_TAB_ID,
  SET_GAME_IS_STARTING,
  SET_INVITE_SETTINGS_OPENED,
}

interface IQuickGameSectionReducerActionSetMounted {
  type: eQuickGameSectionReducerActionTypes.SET_MOUNTED;
  payload: boolean;
}

interface IQuickGameSectionReducerActionSetTabs {
  type: eQuickGameSectionReducerActionTypes.SET_TABS;
  payload: IQuickGameTab[];
}

interface IQuickGameSectionReducerActionSetLoading {
  type: eQuickGameSectionReducerActionTypes.SET_LOADING;
  payload: boolean;
}

interface IQuickGameSectionReducerActionSetError {
  type: eQuickGameSectionReducerActionTypes.SET_ERROR;
  payload: string;
}

interface IQuickGameSectionReducerActionSetActiveTabIdError {
  type: eQuickGameSectionReducerActionTypes.SET_ACTIVE_TAB_ID;
  payload: GameRatingMode;
}

interface IQuickGameSectionReducerActionSetGameIsStartingError {
  type: eQuickGameSectionReducerActionTypes.SET_GAME_IS_STARTING;
  payload: boolean;
}

interface IQuickGameSectionReducerActionSetInviteSettingsOpened {
  type: eQuickGameSectionReducerActionTypes.SET_INVITE_SETTINGS_OPENED;
  payload: boolean;
}

export type IQuickGameSectionReducerAction =
  | IQuickGameSectionReducerActionSetMounted
  | IQuickGameSectionReducerActionSetTabs
  | IQuickGameSectionReducerActionSetLoading
  | IQuickGameSectionReducerActionSetError
  | IQuickGameSectionReducerActionSetActiveTabIdError
  | IQuickGameSectionReducerActionSetGameIsStartingError
  | IQuickGameSectionReducerActionSetInviteSettingsOpened;
